<template>
  <div class="vip-wapper">
    <!-- <span class="title2 hidden-sm-and-up">{{ $t("vip等级") }}</span> -->
    <div class="right">
      <img src="@/assets/images/vip-right.png" alt="" />
    </div>
    <div class="left">
      <img src="@/assets/images/vip-right.png" alt="" />
    </div>
    <swiper :options="swiperOptionThumbs" class="swiper-vip" ref="swiper" v-if="initList.length">
      <swiper-slide v-for="(item, index) in initList" :key="index" class="swiper">
        <div class="vip-item" @click="toVip">
          <img :src="item.image" class="level-img" />

          <div class="title">{{ item.level_name }}</div>
          <!-- <div class="cashback">{{ $t("现金返还") }}</div>
          <div class="bfb-txt" v-if="item.cashback != undefined">
            {{ item.cashback.toFixed(1) }}%
            <img src="@/assets/images/vip-liwu.png" alt="" class="top" />
          </div> -->
          <div class="cashback">{{ $t("等级彩金") }}</div>
          <div class="num">{{ item.level_gold }}</div>
          <div class="cashback" v-if="item.week_reward_amount!=0">{{ $t("周礼金") }}</div>
          <div class="bfb-txt" v-if="item.week_reward_amount!=0">
            {{ item.week_reward_amount }}
            <img v-if="item.week_is_receive_vip_reward!=1" src="@/assets/images/vip-liwu.png" alt="" class="top" />
            <img v-else src="@/assets/images/vip-liwu2.png" alt="" class="top top2" />
          </div>
          <div class="cashback" v-if="item.month_reward_amount!=0">{{ $t("月礼金") }}</div>
          <div class="bfb-txt" v-if="item.month_reward_amount!=0">
            {{ item.month_reward_amount }}
            <img v-if="item.month_is_receive_vip_reward!=1" src="@/assets/images/vip-liwu.png" alt="" class="top" />
            <img v-else src="@/assets/images/vip-liwu2.png" alt="" class="top top2" />
          </div>
          <div :class="['vip-title']" v-if="$store.state.userInfo.level_id==item.id">{{ $t("VIP等级") }}</div>
          <div :class="['jdt-wapper']" v-if="$store.state.userInfo.level_id==item.id">
            <div class="jdt">
              <div class="jdt-content" :style="{width:`${$store.state.userInfo.next_level_rate}%`}"></div>
            </div>
            <span>{{ $store.state.userInfo.next_level_rate }}%</span>
          </div>
          <!-- <div class="bfb-txt">
            <span style="color:#87a1c7;">{{ $t("周礼金") }}：</span>
            {{ item.week_reward_amount }}
            <img src="@/assets/images/vip-liwu.png" alt="" class="top" />
          </div>
          <div class="bfb-txt">
            <span style="color:#87a1c7">{{ $t("月礼金") }}：</span>
            {{ item.month_reward_amount}}
            <img src="@/assets/images/vip-liwu.png" alt="" class="top" />
          </div> -->
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOptionThumbs: {
        autoplay: 3000,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".right",
          prevEl: ".left",
        },
        slidesPerView: 3,
        centeredSlides: true,
        breakpoints: {
          540: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        },
      },
    };
  },
  props: {
    list: {
      default: [],
    },
    cashbackList: {
      default: [],
    },
  },
  computed: {
    /**
     * 格式化list列表,添加现金返还数据,取最大数据显示
     * */
    initList() {
      let list = JSON.parse(JSON.stringify(this.list));
      list.forEach((item) => {
        this.cashbackList.forEach((cashbackListItem) => {
          cashbackListItem.vipwater.forEach((vipwaterItem) => {
            if (item.level_name == vipwaterItem.level_name) {
              if (item.cashback) {
                if (item.cashback < vipwaterItem.gbc_rate) {
                  item.cashback = vipwaterItem.gbc_rate;
                }
              } else {
                item.cashback = vipwaterItem.gbc_rate;
              }
            }
          });
        });
      });
      console.log(list)
      return list;
    },
  },
  watch:{
    initList:{
      immediate:true,
      handler(){
        setTimeout(()=>{
          const page = this.initList.findIndex(item=>item.id === this.$store.state.userInfo.level_id)
          this.$refs?.swiper?.swiper.slideTo(page);
        },50)
      },

    }
  },
  methods:{
    toVip(){
      this.$router.push("/rank-system")
    }
  }
};
</script>

<style scoped>
.vip-wapper {
  background: #07503F;
  border-radius: 6px;
  padding: 10px 0;
  position: relative;
}
.right,
.left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 2;
  user-select: none;
}
.right img,
.left img {
  width: 100%;
}
.right {
  right: 34px;
}
.left {
  left: 34px;
  transform: translateY(-50%) rotate(180deg);
}
.vip-item {
  width: 150px;
  min-height: 210px;
  flex-shrink: 0;
  /* height: 188px; */
  background-image: url(../../../assets/images/vip-bg.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;
  position: relative;
  transform-origin: center bottom;
  cursor: pointer;
}
.title2{
  position: absolute;
  top: 13px;
  left: 13px;
  font-weight: bold;
  color: #C2F6E6;
  z-index: 2;
}
@media screen and (min-width: 540px) {
  .vip-item {
    transform: scale(1);
    opacity: 1;
    padding-bottom: 10px;
    font-size: 12px;
  }
  .swiper-slide-active .vip-item {
    transform: scale(1.3);
    z-index: 3;
    background-image: url(../../../assets/images/vip-bg-ac.png);
    opacity: 1;
  }
  .swiper-slide-next .vip-item,
  .swiper-slide-prev .vip-item {
    transform: scale(1);
    z-index: 2;
    opacity: 1;
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
}
.swiper-vip {
  width: 558px;
  padding-top: 80px;
  max-width: 100%;
}
.level-img {
  width: 52px;
  margin-top: -16px;
}
.title {
  margin-bottom: 4px;
}
.cashback {
  color: #D7FFC4;
  font-size: 12px;
}
.bfb-txt {
  color: #FFD700;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.bonus-sa-rank {
  color: #87a1c7;
  font-size: 12px;
  margin-bottom: 6px;
}
.num {
  color: #FFD700;
  font-size: 15px;
}
.vip-title {
  color: #C2F6E6;
  font-size: 12px;
  margin-bottom: 6px;
}
.jdt {
  height: 4px;
  background-color: #00241D;
  border-radius: 100vh;
  flex: 1;
  margin-right: 6px;
}
.jdt-wapper {
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 80%;
  color: #D7FFC4;
}
.jdt-content {
  height: 100%;
  border-radius: 100vh;
  background-color: #FFD700;
  width: 50%;
}
.top {
  width: 14px;
  margin-left: 4px;
}
.top2{
  transform: scale(1.6);
}
.hide{
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .right,
  .left {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .vip-wapper{
    padding: 0;
    background-color: transparent;
  }
  .swiper-vip{
    --width:calc(100% + 20vw);
    width: var(--width);
    max-width: var(--width);
    padding: 30px 0;
    margin: -10px 0 -30px;
    margin-left: -10vw;
  }
  .vip-item{
    width: 100%;
    background: #104D3E;
    border: 2px solid #1B604D;
    padding: 18px 18px 27px;
    border-radius: 20px;
    min-height: 267px;
    transform: scale(.9)
  }
  .swiper-slide-active .vip-item{
    background-image: linear-gradient(to bottom,#26CC82,#009D43);
    border: 2px solid #86F6CC;
    transform: scale(1)
  }
  .vip-item *{
    font-size: 14px !important;
  }
  .level-img{
    margin-top: -50px;
    width: 73px;
  }
  .bfb-txt,.num{
    margin-bottom: 13px;
  }
  .jdt-wapper{
    width: 70%;
  }
  .jdt{
    height: 6px;
  }
}
</style>
